import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "../stylesheets/application"

Rails.start()
ActiveStorage.start()

$(function() {
  $('.hamburger-menu').click(function() {
    $(this).toggleClass('active');
    if ($(this).hasClass('active')) {
      $('.global-menu-sp').addClass('active');
      $('.global-menu-sp').show();
    } else {
      $('.global-menu-sp').removeClass('active');
      $('.global-menu-sp').hide();
    }
  });

  $('.global-menu-sp a[href]').click(function() {
    $('.global-menu-sp').removeClass('active');
    $('.hamburger-menu').removeClass('active');
  });

  $('.global-menu-sp').hide();
});
